a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
@font-face {
  font-family: Lato;
  src: url(./assets/fonts/lato400.woff2) format('woff2'),
    url(./assets/fonts/lato400.woff) format('woff'),
    url(./assets/fonts/lato400.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Lato;
  src: url(./assets/fonts/lato500.woff2) format('woff2'),
    url(./assets/fonts/lato500.woff) format('woff'),
    url(./assets/fonts/lato500.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Lato;
  src: url(./assets/fonts/lato600.woff2) format('woff2'),
    url(./assets/fonts/lato600.woff) format('woff'),
    url(./assets/fonts/lato600.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Lato;
  src: url(./assets/fonts/lato700.woff2) format('woff2'),
    url(./assets/fonts/lato700.woff) format('woff'),
    url(./assets/fonts/lato700.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Lato;
  src: url(./assets/fonts/lato800.woff2) format('woff2'),
    url(./assets/fonts/lato800.woff) format('woff'),
    url(./assets/fonts/lato800.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
body {
  font-family: Lato, Helvetica, Arial, Sans-Serif;
  font-size: 1em;
  line-height: 1.25em;
  color: #161616;
  background-color: #fff;
  position: relative;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  max-width: 1290px;
}
.image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}
.link {
  color: #fff;
  text-decoration: none;
}
.link--active {
  color: #556e8d;
}
.burger {
  cursor: pointer;
  display: block;
  width: 32px;
  height: 32px;
  position: relative;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
}
.burger__bar {
  display: block;
  width: 24px;
  height: 1px;
  position: absolute;
  right: 4px;
  background-color: #fff;
}
.burger__bar:first-child,
.burger__bar:nth-child(3) {
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.burger__bar:first-child {
  top: 10px;
}
.burger__bar:nth-child(2) {
  top: 16px;
  -webkit-transition: opacity 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
}
.burger__bar:nth-child(3) {
  top: 22px;
  width: 18px;
}
.burger--active .burger__bar:first-child,
.burger--active .burger__bar:nth-child(3) {
  top: 16px;
}
.burger--active .burger__bar:first-child {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.burger--active .burger__bar:nth-child(2) {
  opacity: 0;
}
.burger--active .burger__bar:nth-child(3) {
  width: 24px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.hero {
  background-image: url(./assets/images/bg.jpg);
  background-size: cover;
  background-position: top;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}
.hero:after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(22, 22, 22, 0.6);
}
.hero__header,
.hero__intro {
  position: relative;
  z-index: 10;
}
.hero__header {
  padding-top: 52px;
}
.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 1.125em;
  line-height: 1.222222222222222em;
  position: relative;
}
.header__pull {
  display: none;
  position: absolute;
  top: -6px;
  right: 0;
}
.header-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.header-menu,
.header-menu__item {
  padding-left: 14px;
  padding-right: 14px;
}
.intro {
  padding-top: 158px;
  padding-bottom: 210px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}
.intro__heading {
  margin-bottom: 50px;
  font-size: 4em;
  font-weight: 800;
  line-height: 1.1875em;
}
.intro__list {
  margin-bottom: 64px;
}
.intro-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.intro-button__button {
  margin-bottom: 18px;
}
.intro-button__description {
  font-size: 1.25em;
  line-height: 1.3em;
}
.intro-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.intro-list,
.intro-list__item {
  padding-left: 21px;
  padding-right: 21px;
}
.intro-list-item {
  padding-left: 24px;
  font-size: 1.5em;
  line-height: 1.166666666666667em;
  background-image: url(./assets/images/check_white_18.svg);
  background-position: top 6px left 0;
  background-size: 18px;
  background-repeat: no-repeat;
}
@media screen and (max-width: 1100px) {
  .hero__header {
    padding-top: 32px;
  }
  .header {
    font-size: 1em;
    line-height: 1.25em;
  }
  .intro {
    padding-top: 128px;
    padding-bottom: 128px;
  }
  .intro__heading {
    margin-bottom: 32px;
    font-size: 3.25em;
    line-height: 1.230769230769231em;
  }
  .intro__list {
    margin-bottom: 48px;
  }
  .intro-button__button {
    margin-bottom: 16px;
  }
  .intro-button__description {
    font-size: 1em;
    line-height: 1.375em;
  }
  .intro-list,
  .intro-list__item {
    padding-left: 16px;
    padding-right: 16px;
  }
  .intro-list-item {
    padding-left: 22px;
    font-size: 1.125em;
    line-height: 1.222222222222222em;
    background-position: top 4px left 0;
    background-size: 16px;
  }
}
@media screen and (max-width: 860px) {
  .header__phone {
    display: none;
  }
  .intro {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .intro__heading {
    font-size: 3em;
    line-height: 1.25em;
  }
  .intro__list {
    margin-bottom: 32px;
  }
  .intro-list {
    padding-left: 0;
    padding-right: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .intro-list__item {
    margin-bottom: 16px;
    padding-left: 0;
    padding-right: 32px;
    width: 33.33%;
  }
  .intro-button {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .intro-button__button {
    margin-right: 18px;
    margin-bottom: 0;
  }
  .intro-button__description {
    font-size: 0.875em;
    line-height: 1.428571428571429em;
  }
}
@media screen and (max-width: 720px) {
  .header__menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #1b2b3f;
    z-index: 1000;
  }
  .header__menu--active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-animation: fade 0.25s 1;
    animation: fade 0.25s 1;
  }
  .header__pull {
    display: block;
    z-index: 2000;
  }
  .header-menu {
    padding-left: 0;
    padding-right: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .header-menu__item {
    margin-bottom: 24px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 640px) {
  .intro-list__item {
    width: 50%;
  }
}
@media screen and (max-width: 460px) {
  .header__phone {
    display: none;
  }
  .intro {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .intro__heading {
    font-size: 2.25em;
    line-height: 1.333333333333333em;
  }
  .intro__list {
    margin-bottom: 32px;
  }
  .intro-button {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .intro-button__button {
    margin-right: 0;
    margin-bottom: 16px;
  }
  .intro-list__item {
    padding-right: 16px;
  }
  .intro-list-item {
    padding-left: 18px;
    font-size: 1em;
    line-height: 1.25em;
    background-position: top 4px left 0;
    background-size: 12px;
  }
}
@media screen and (max-width: 370px) {
  .intro {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .intro__heading {
    font-size: 2em;
    line-height: 1.375em;
  }
  .intro-list__item {
    padding-right: 0;
    width: 100%;
  }
}
.no-scroll {
  overflow: hidden;
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.button {
  padding-left: 30px;
  padding-right: 30px;
  display: block;
  height: 60px;
  line-height: 60px;
  font-size: 1.5em;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  background-color: #01835e;
  border-radius: 10px;
  border: none;
  outline: none;
}
@media screen and (max-width: 1100px) {
  .button {
    padding-left: 24px;
    padding-right: 24px;
    height: 52px;
    line-height: 52px;
    font-size: 1.25em;
  }
}
@media screen and (max-width: 460px) {
  .button {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.125em;
  }
}
.advantages {
  padding-top: 80px;
  padding-bottom: 150px;
}
.advantages__items {
  margin-left: -10px;
  margin-right: -10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.advantages__item {
  padding-left: 10px;
  padding-right: 10px;
  width: 33.33%;
}
.advantages-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.advantages-item__icon {
  margin-bottom: 24px;
  width: 84px;
  height: 74px;
  position: relative;
}
.advantages-item__icon:before {
  display: block;
  content: '';
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background-color: #9cffe2;
  position: absolute;
  top: -19px;
  left: 0;
}
.advantages-item__image {
  position: relative;
}
.advantages-item__info {
  text-align: center;
}
.advantages-item__heading {
  margin-bottom: 12px;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.083333333333333em;
}
.advantages-item__description {
  font-size: 1.125em;
  font-weight: 500;
  line-height: 1.222222222222222em;
  color: #676767;
}
@media screen and (max-width: 1100px) {
  .advantages {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .advantages-item__heading {
    font-size: 1.25em;
    line-height: 1.1em;
  }
  .advantages-item__description {
    font-size: 0.875em;
    line-height: 1.285714285714286em;
  }
}
@media screen and (max-width: 580px) {
  .advantages__items {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .advantages__item {
    margin-bottom: 67px;
    width: 100%;
  }
  .advantages__item:last-child {
    margin-bottom: 0;
  }
  .advantages-item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .advantages-item__icon {
    margin-right: 24px;
    margin-bottom: 0;
  }
  .advantages-item__info {
    text-align: left;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
@media screen and (max-width: 370px) {
  .advantages-item__icon {
    margin-right: 16px;
    width: 64px;
    height: 57px;
  }
  .advantages-item__icon:before {
    width: 44px;
    height: 44px;
    top: -14px;
  }
  .advantages-item__heading {
    margin-bottom: 8px;
    font-size: 1em;
    line-height: 1.125em;
  }
}
.contacts {
  background-color: #f7f8fc;
  background-image: url(./assets/images/contacts.jpg);
  background-position: 100% 100%;
  background-size: 644px 380px;
  background-repeat: no-repeat;
}
.contacts__container {
  padding-top: 92px;
  padding-bottom: 126px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 1;
}
.contacts__heading {
  margin-bottom: 24px;
  font-size: 4em;
  font-weight: 800;
  line-height: 1.203125em;
}
.contacts-phone {
  padding-left: 37px;
  display: block;
  font-size: 2.25em;
  font-weight: 600;
  line-height: 1.194444444444444em;
  color: #161616;
  text-decoration: none;
  background-size: 27px;
  background-position: top 12px left 0;
  background-repeat: no-repeat;
}
@media screen and (max-width: 1440px) {
  .contacts {
    background-size: 440px 260px;
  }
}
@media screen and (max-width: 1100px) {
  .contacts__heading {
    margin-bottom: 16px;
    font-size: 3.25em;
    line-height: 1.230769230769231em;
  }
  .contacts-phone {
    padding-left: 34px;
    font-size: 2em;
    line-height: 1.1875em;
    background-size: 24px;
    background-position: top 10px left 0;
  }
}
@media screen and (max-width: 1000px) {
  .contacts {
    background-size: 320px 189px;
  }
}
@media screen and (max-width: 860px) {
  .contacts__heading {
    margin-bottom: 12px;
    font-size: 3em;
    line-height: 1.25em;
  }
  .contacts .contacts-phone {
    font-size: 1.875em;
    line-height: 1.2em;
  }
}
@media screen and (max-width: 800px) {
  .contacts {
    background-size: 240px 142px;
  }
}
@media screen and (max-width: 640px) {
  .contacts {
    background-size: cover;
    position: relative;
  }
  .contacts:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(247, 248, 252, 0.85);
  }
  .contacts__container {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}
@media screen and (max-width: 460px) {
  .contacts__heading {
    margin-bottom: 8px;
    font-size: 2.25em;
    line-height: 1.333333333333333em;
  }
  .contacts .contacts-phone {
    padding-left: 26px;
    font-size: 1.5em;
    line-height: 1.25em;
    background-size: 16px;
    background-position: top 9px left 0;
  }
}
@media screen and (max-width: 370px) {
  .contacts__heading {
    font-size: 2em;
    line-height: 1.375em;
  }
  .contacts .contacts-phone {
    font-size: 1.25em;
    line-height: 1.3em;
    background-position: top 7px left 0;
  }
}
.footer {
  margin-top: auto;
  padding-top: 28px;
  padding-bottom: 28px;
  color: #fff;
  background-color: #1b2b3f;
}
.footer__container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.footer-menu,
.footer__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.footer-menu__item {
  margin-left: 20px;
}
@media screen and (max-width: 1000px) {
  .footer__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .footer__copyrights {
    text-align: center;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    font-size: 0.875em;
    opacity: 0.666;
  }
  .footer__menu {
    margin-bottom: 24px;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
@media screen and (max-width: 440px) {
  .footer-menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer-menu__item {
    margin-bottom: 16px;
    text-align: center;
  }
  .footer-menu__item:last-child {
    margin-bottom: 0;
  }
}


.white {
  background-color: rgb(238, 238, 238);
  color: black;
  border-radius: 10px;
  opacity: 0.8;
  border: 1px solid black;
}

.hide {
  display: none;
}
